import { styled, Typography } from '@mui/material'
import { useTranslate } from 'react-admin'
import greeting from '../../utils/greeting'
import { getUserName } from '../../utils/userDetails'
import AppList from './AppList'
import TicketsList from './TicketsList'

const OrganizationDashboard = () => {
  const translate = useTranslate()

  return (
    <Root className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        {greeting('dashboard.greeting', getUserName() || '', translate)}
      </Typography>
      <Typography variant="body1" className={classes.description}>
        {translate('dashboard.description')}
      </Typography>
      <AppList resource="app_overview" basePath="/app_overview" />
      <TicketsList resource="tickets" basePath="/tickets" />
    </Root>
  )
}

export default OrganizationDashboard

const PREFIX = 'xxllncOrganizationDashboard'

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: { },
  [`& .${classes.title}`]: {
    fontSize: '24px',
    marginBottom: '4px',
    fontWeight: '300',
  },
  [`& .${classes.description}`]: {
    fontSize: '14px',
    marginTop: '0',
  },
}))