import { Box } from '@mui/material'
import React, { FC } from 'react'
import { useGetList, useTranslate, WithListContext } from 'react-admin'
import { DomainFilter } from '../../components'
import { CustomSwitch } from '../../components/CustomSwitch'
import CustomList from '../../components/list/CustomList'
import { AppOrganizationOut, AppOverview } from '../../types/ecoRequests'
import { getOrganizationId } from '../../utils/userDetails'
import AppTable from './AppTable'

export interface AppWithOrganization extends AppOverview {
  appOrganizationId?: string
}

const FilterSidebar = () => (
  <Box sx={{ width: '100%', order: -1 }}>
    <DomainFilter />
  </Box>
)

const AppList: FC = () => {
  const translate = useTranslate()
  const [modules, setModules] = React.useState(localStorage.getItem('modules') === 'true')

  const { data: appOrganizations } = useGetList<AppOrganizationOut>(
    'app_organization',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
      filter: { organizationId: getOrganizationId() }
    }
  )

  const mergeData = (appOverview: AppOverview[]): AppWithOrganization[] =>
    appOrganizations?.map(appOrg => {
      const matchingApp = appOverview?.find(app => app.id === appOrg.appId)
      if (matchingApp) {
        return {
          ...matchingApp,
          appOrganizationId: appOrg.id
        }
      }
      return null
    }).filter(app => app !== null) as AppWithOrganization[]

  const onChangeModules = () => {
    setModules(!modules)
    localStorage.setItem('modules', (!modules).toString())
  }

  return (
    <CustomList
      title='resources.app.title'
      aside={<FilterSidebar />}
      perPage={100}
      pagination={false}
      exporter={false}
    >
      <WithListContext render={({ data }) => {
        const appOverview = data as AppOverview[]
        const merged = mergeData(appOverview)
        const filtered = appOverview?.filter(app => !merged?.some(mergedApp => mergedApp.id === app.id))

        return (
          <>
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent:'flex-end',
              position: 'absolute',
              top: '-96px',
              right: '14px'
            }}>
              <CustomSwitch
                label={translate('resources.app.showModules')}
                checked={modules}
                onChange={onChangeModules}
              />
            </div>
            <AppTable
              title="inUse"
              data={merged || []}
              modules={modules}
            />
            <AppTable
              title="requestable"
              data={filtered || []}
              modules={modules}
            />
          </>
        )}} />
    </CustomList>
  )
}

export default AppList