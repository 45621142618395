import { Resource } from 'react-admin'
import { debug } from '../test'
import { Permissions } from './../types/ecoTypes'
import appInstance from './AppInstance'
import appIntegration from './AppIntegration'
import appLicence from './AppLicence'
import appModule from './AppModule'
import appOrganization from './AppOrganization'
import appOrganizationIntegration from './AppOrganizationIntegration'
import appOrganizationLicence from './AppOrganizationLicence'
import appOrganizationModule from './AppOrganizationModule'
import appReport from './AppReport'
import appRole from './AppRole'
import apps from './Apps'
import auditLogs from './AuditLogs'
import authConnection from './AuthConnection'
import authMember from './AuthMember'
import authMemberRole from './AuthMemberRole'
import contracts from './Contracts'
import externalAppsList from './ExternalApps'
import hostAliases from './HostAliases'
import manageApps from './ManageApps'
import offers from './Offers'
import organizationAuth from './OrganizationAuth'
import organizations from './Organizations'
import ticketConfig from './TicketConfig'
import tickets from './Tickets'
// import { isFeaturesEnabled } from '../utils/isFeaturesEnabled'

const availableResources = ({scopes}: Permissions): JSX.Element[] => [
  <Resource
    key="external_apps"
    name="app_overview"
    options={{
      label: 'Applicaties',
      scopes: ['eco.user.appinstance:read']
    }}
    list={apps.list}
    show={apps.show}
    icon={apps.icon}
  />,
  <Resource
    key="organization"
    name="organization"
    options={{
      label: 'Organisaties',
      scopes: ['eco.admin.organization:read']
    }}
    list={organizations.list}
    create={
      scopes.some((scope: string) => scope === 'eco.admin.organization:write') ?
        organizations.create : undefined}
    edit={
      scopes.some((scope: string) => scope === 'eco.admin.organization:write') ?
        organizations.edit : undefined}
    show={organizations.show}
    icon={organizations.icon}
  />,
  <Resource
    key={'organization_auth'}
    name="organization_auth"
    options={{
      scopes: ['eco.admin.organization:read']
    }}
    create={
      scopes.some((scope: string) => scope === 'eco.admin.organization:write') ?
        organizationAuth.create : undefined}
    edit={
      scopes.some((scope: string) => scope === 'eco.admin.organization:write') ?
        organizationAuth.edit : undefined
    }
    show={organizationAuth.show}
  />,
  <Resource
    key="auth_connection"
    name="auth_connection"
    options={{
      scopes: [
        'eco.admin.connection:read',
        'eco.user.connection:read'
      ]
    }}
    create={
      scopes.some((scope: string) => scope === 'eco.admin.connection:write') ?
        authConnection.create : undefined}
    edit={
      scopes.some((scope: string) => scope === 'eco.admin.connection:write') ?
        authConnection.edit : undefined}
  />,
  <Resource
    key="auth_member"
    name="auth_member"
    icon={scopes.some((scope: string) => scope === 'eco.user.member:read') ?
      authMember.icon : undefined}
    list={authMember.list}
    create={authMember.create}
    edit={authMember.edit}
    options={{
      label: 'Gebruikers',
      submenu: 'beheer',
      scopes: [
        'eco.admin.member:read',
        'eco.user.member:read'
      ]
    }}
  />,
  <Resource
    key="app"
    name="app"
    options={{
      label: 'Applicaties',
      scopes: ['eco.admin.app:read']
    }}
    list={manageApps.list}
    create={
      scopes.some((scope: string) => scope === 'eco.admin.app:write') ?
        manageApps.create : undefined}
    edit={
      scopes.some((scope: string) => scope === 'eco.admin.app:write') ?
        manageApps.edit : undefined}
    show={manageApps.show}
    icon={manageApps.icon}
  />,
  <Resource
    key="app_instance"
    name="app_instance"
    options={{
      label: 'Omgevingen',
      scopes: [
        'eco.admin.appinstance:read',
        'eco.user.appinstance:read'
      ]
    }}
    icon={scopes.some((scope: string) => scope === 'eco.admin.appinstance:read') ?
      appInstance.icon : undefined}
    list={
      scopes.some((scope: string) => scope === 'eco.admin.appinstance:read') ?
        appInstance.list : undefined}
    show={scopes.some((scope: string) => scope === 'eco.admin.appinstance:read') ?
      appInstance.show : undefined}
    create={
      scopes.some((scope: string) => scope === 'eco.admin.appinstance:write') ?
        appInstance.create : undefined}
    edit={
      scopes.some((scope: string) => scope === 'eco.admin.appinstance:write') ?
        appInstance.edit : undefined}
  />,
  <Resource
    key="app_organization_module"
    name="app_organization_module"
    options={{
      scopes: [
        'eco.admin.appinstance:read',
        'eco.user.appinstance:read',
      ]
    }}
    create={
      scopes.some((scope: string) => scope === 'eco.admin.appinstance:write') ?
        appOrganizationModule.create : undefined}
  />,
  <Resource
    key="app_organization_licence"
    name="app_organization_licence"
    options={{
      scopes: [
        'eco.admin.appinstance:read',
        'eco.user.appinstance:read',
      ]
    }}
    create={
      scopes.some((scope: string) => scope === 'eco.admin.appinstance:write') ?
        appOrganizationLicence.create : undefined}
  />,
  <Resource
    key="app_organization_integration"
    name="app_organization_integration"
    options={{
      scopes: [
        'eco.admin.appinstance:read',
        'eco.user.appinstance:read',
      ]
    }}
    create={
      scopes.some((scope: string) => scope === 'eco.admin.appinstance:write') ?
        appOrganizationIntegration.create : undefined}
  />,
  <Resource
    key="app_integration"
    name="app_integration"
    options={{
      scopes: [
        'eco.admin.app:read',
        'eco.user.app:read',
      ]
    }}
    create={
      scopes.some((scope: string) => scope === 'eco.admin.app:write') ?
        appIntegration.create : undefined}
    edit={
      scopes.some((scope: string) => scope === 'eco.admin.app:write') ?
        appIntegration.edit : undefined}
  />,
  <Resource
    key="app_module"
    name="app_module"
    options={{
      scopes: [
        'eco.admin.app:read',
        'eco.user.app:read',
      ]
    }}
    create={
      scopes.some((scope: string) => scope === 'eco.admin.app:write') ?
        appModule.create : undefined}
    edit={
      scopes.some((scope: string) => scope === 'eco.admin.app:write') ?
        appModule.edit : undefined}
  />,
  <Resource
    key="app_licence"
    name="app_licence"
    options={{
      scopes: [
        'eco.admin.app:read',
        'eco.user.app:read',
      ]
    }}
    create={
      scopes.some((scope: string) => scope === 'eco.admin.app:write') ?
        appLicence.create : undefined}
    edit={
      scopes.some((scope: string) => scope === 'eco.admin.app:write') ?
        appLicence.edit : undefined}
  />,
  <Resource
    key="app_role"
    name="app_role"
    options={{
      scopes: [
        'eco.admin.app:read',
        'eco.user.app:read',
      ]
    }}
    create={
      scopes.some((scope: string) => scope === 'eco.admin.app:write') ?
        appRole.create : undefined}
  />,
  <Resource
    key="auth_member_role"
    name="auth_member_roles"
    options={{
      scopes: [
        'eco.admin.app:read',
        'eco.user.app:read',
      ]
    }}
    create={
      scopes.some((scope: string) => scope === 'eco.admin.app:write') ?
        authMemberRole.create : undefined}
    list={authMemberRole.list}
  />,
  <Resource
    key="app_report"
    name="app_report"
    options={{
      scopes: [
        'eco.admin.app:read',
        'eco.user.app:read',
      ]
    }}
    create={
      scopes.some((scope: string) => scope === 'eco.admin.app:write') ?
        appReport.create : undefined}
    edit={
      scopes.some((scope: string) => scope === 'eco.admin.app:write') ?
        appReport.edit : undefined}
  />,
  <Resource
    key="app_organization"
    name="app_organization"
    options={{
      scopes: ['eco.admin.apporganization:read']
    }}
    create={
      scopes.some((scope: string) => scope === 'eco.admin.apporganization:write') ?
        appOrganization.create : undefined}
    edit={
      scopes.some((scope: string) => scope === 'eco.admin.apporganization:write') ?
        appOrganization.edit : undefined}
    show={appOrganization.show}
  />,
  <Resource
    key="hostalias"
    name="hostalias"
    options={{
      scopes: [
        'eco.admin.appinstance:read',
        'eco.user.appinstance:read'
      ]
    }}
    create={
      scopes.some((scope: string) => scope === 'eco.admin.appinstance:write') ?
        hostAliases.create : undefined}
    edit={
      scopes.some((scope: string) => scope === 'eco.admin.appinstance:write') ?
        hostAliases.edit : undefined}
  />,
  <Resource
    key="offers"
    name="offers"
    options={{
      label: 'Offertes',
      scopes: ['eco.user.offers:read']
    }}
    list={offers.list}
    icon={offers.icon}
  />,
  <Resource
    key="contracts"
    name="contracts"
    options={{
      label: 'Contracten',
      scopes: ['eco.user.offers:read']
    }}
    list={contracts.list}
    show={contracts.show}
    icon={contracts.icon}
  />,
  <Resource
    key="tickets"
    name="tickets"
    options={{
      label: 'Meldingen',
      scopes: ['eco.user.offers:read']
    }}
    list={tickets.list}
    edit={tickets.edit}
    create={tickets.create}
    icon={tickets.icon}
  />,
  <Resource
    key="auditlog"
    name="auditlog"
    list={auditLogs.list}
    icon={auditLogs.icon}
    options={{
      label: 'Logboek',
      submenu: 'beheer',
      scopes: [
        'eco.admin.auditlog:read',
        'eco.user.auditlog:read'
      ]
    }}
  />,
  <Resource
    key="ticket_config"
    name="ticket_config"
    options={{
      label: 'Meldingen formulieren',
      submenu: 'beheer',
      scopes: ['eco.admin.ticketConfig:read']
    }}
    list={ticketConfig.list}
    show={ticketConfig.show}
    edit={ticketConfig.edit}
    create={ticketConfig.create}
    icon={ticketConfig.icon}
  />,
  <Resource
    key="externalApps"
    name="externalApps"
    options={{
      label: 'Externe applicaties',
      submenu: 'beheer',
      scopes: ['eco.admin.appinstance:read']
    }}
    list={externalAppsList.list}
    edit={externalAppsList.edit}
    create={externalAppsList.create}
    icon={externalAppsList.icon}
  />,
  <Resource
    key="review"
    name="review"
    options={{
      scopes: ['eco.user.appinstance:read']
    }}
  />,
  <Resource
    key="tickets/incidents/categories"
    name="tickets/incidents/categories"
    options={{
      scopes: ['eco.admin.apporganization:read']
    }}
  />,
]

const fallback = (): JSX.Element[] => [
  <Resource
    key="app_overview"
    name="app_overview"
    list={apps.list}
    show={apps.show}
    icon={apps.icon}
  />
]

export const resourcesForScopes = ({ scopes }: Permissions): JSX.Element[] => {
  debug && console.log({scopes})
  if (!scopes || scopes.length === 0) {
    console.warn('No scopes received, returning fallback resource')
    return fallback()
  }
  const resources = availableResources({ scopes })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    .filter(resource => resource.props.options.scopes.find(scope => scopes.includes(scope)) !== undefined)
  if (!resources || resources.length === 0) {
    console.warn('No resources received, returning fallback resource')
    return fallback()
  }
  return resources
}
