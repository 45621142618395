import PersonIcon from '@mui/icons-material/Person'
import { Avatar, Box, Grid, styled, Typography } from '@mui/material'
import { FC } from 'react'
import { required, TextInput, useEditContext, useTranslate } from 'react-admin'
import { xxllncColor } from '../../layout/themes'
import { dateFormatter } from '../../utils/dateFormatter'
import getInitials from '../../utils/getInitials'
import { extractRequest, TicketRecord } from './Tickets.list'
import TicketStatus from './TicketStatus'

// On line we do some replaceAll's to fix the new lines in the request
// TopDesk does not accept new lines in the request, so we added \\r and \\n instead when creating the ticket
// When presenting the ticket, we need to replace them with \r and \n

const personBgColor = '#474747'
const topDeskUrl = 'https://xxllnc.topdesk.net'

const TicketForm: FC = () => {
  const translate = useTranslate()
  const { record } = useEditContext<TicketRecord>()

  return (
    <StyledGrid container spacing={2} className={classes.root}>
      <Grid item xs={12} className={classes.item}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5">{ record?.number }<span> - { record?.briefDescription }</span></Typography>
          <Box className={classes.info}>
            <span><b>{`${translate('data.applicationQuestion')}: `}</b>{ record?.category}</span>
            <span><b>{`${translate('data.type')}: `}</b>{ record?.type}</span>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Avatar
            variant="circular"
            sx={{ width: 44, height: 44, backgroundColor: personBgColor, fontSize: '12pt', fontWeight: 600 }}
          >
            { getInitials(record?.callerName) }
          </Avatar>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', width: '100%' }}>
            <Typography variant="subtitle1">{ record?.callerName }</Typography>
            <Typography variant="body1" sx={{ fontSize: '8pt', marginBottom: '20px' }}>
              {`${translate('resources.tickets.loggedOn')} ${dateFormatter(record?.creationDate)}`}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Typography variant="subtitle1" sx={{ marginTop: '20px' }}>Wat is je vraag/verzoek?</Typography>
          <Typography variant="body1" sx={{ fontSize: '10pt', whiteSpace: 'pre-line', overflowWrap: 'break-word', marginBottom: '20px' }}>
            { extractRequest(record)?.replaceAll('\\r', '\r')?.replaceAll('\\n', '\n') }
          </Typography>
          <TicketStatus />
          <Box className={classes.comments}>
            { record?.reactions?.filter(reaction => !!reaction.memoText || !!reaction.downloadUrl)
              .map((reaction, index) => (
                <Box
                  key={reaction.id}
                  className={classes.commenRow}
                  sx={{ backgroundColor: index % 2 === 0 ? xxllncColor.light80 : 'transparant' }}>
                  <Avatar
                    variant="circular"
                    sx={{ backgroundColor: reaction.person ? personBgColor : '#bdbdbd', fontSize: '11pt' }}
                  >
                    { reaction.person ? getInitials(reaction.person) : ( <PersonIcon /> ) }
                  </Avatar>
                  <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', width: '100%' }}>
                    <Box key={reaction.id} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Typography variant="subtitle1" sx={{ fontSize: '9pt' }}>
                        { reaction.person || reaction.operator || translate('data.operator') }
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: '9pt', whiteSpace: 'pre-line' }}>
                        { dateFormatter(reaction.entryDate) }
                      </Typography>
                    </Box>
                    { reaction.memoText && <Typography variant="body1" sx={{ fontSize: '9pt', whiteSpace: 'pre-line' }}>
                      { reaction.memoText }
                    </Typography> }
                    { reaction.fileName && reaction.downloadUrl && (
                      <a href={`${topDeskUrl}${reaction.downloadUrl}`} target="_blank" >
                        <Typography variant="body1" sx={{ fontSize: '9pt', whiteSpace: 'pre-line' }}>
                          { reaction.fileName }
                        </Typography>
                      </a>)}
                  </Box>
                </Box>
              ))}
          </Box>
          <TextInput
            source="action"
            label="resources.tickets.reactionPlaceholder"
            multiline
            validate={required()}
            disabled={!!record?.closed}
            fullWidth
          />
        </Box>
      </Grid>
    </StyledGrid>
  )
}

const PREFIX = 'xxllncTicketsForm'

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  info: `${PREFIX}-info`,
  comments: `${PREFIX}-comments`,
  commenRow: `${PREFIX}-commenRow`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    [theme.breakpoints.down('md')]: {
      margin: '0 20px',
      width: 'calc(100% - 35px)',
    }
  },
  [`& .${classes.item}`]: {
    paddingLeft: 0,
  },
  [`& .${classes.info}`]: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottom: `1px solid ${xxllncColor.dark20}`,
    paddingBottom: '24px',
    marginBottom: '30px',
    marginTop: '16px',
    ['& span']: {
      fontSize: '10pt',
      color: '#6E6E74',
      paddingRight: '20px',
      marginRight: '20px',
      ['&:not(:last-child)']: {
        borderRight: `1px solid ${xxllncColor.dark20}`,
      },
    },
  },
  [`& .${classes.comments}`]: {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px 0 10px 0',
    width: '100%',
  },
  [`& .${classes.commenRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '5px',
    padding: '16px',
  },
}))

export default TicketForm