/* eslint-disable camelcase */
/* eslint-disable security/detect-object-injection */
import { Box, Divider, Grid, LinearProgress, styled, Typography } from '@mui/material'
import { FC } from 'react'
import {
  AutocompleteInput,
  Create,
  FormDataConsumer,
  maxLength,
  minLength,
  RaRecord,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetList
} from 'react-admin'
import { ActionsToolbar, SimpleSaveToolbar } from '../../components'
import { Question } from '../../components/questions'
import { xxllncColor } from '../../layout/theme/color'
import { TicketConfigurationOut } from '../../types/ecoRequests'
import { dateFormatter } from '../../utils/dateFormatter'

const validateDescription = [required(), minLength(10), maxLength(80)]

interface TicketCreateProps extends RaRecord {
  description: string
  priority: string
}

const TicketsCreate: FC = () => {
  const { data: ticketConfig } = useGetList<TicketConfigurationOut>(
    'ticket_config',
    {
      pagination: { page: 1, perPage: 100 },
    }
  )

  const mergeUniqueTags = (): string[] => {
    if (!ticketConfig) return []
    const allTags = ticketConfig.flatMap(ticket => ticket.tags ?? [])
    return Array.from(new Set(allTags)).filter((tag): tag is string => tag !== undefined)
  }

  const transform = (data: TicketCreateProps) => {
    const { entryTypeName, priority, tag, briefDescription, application, ...rest } = data
    const selectedApplication = ticketConfig?.find(({ id }) => id === application) as TicketConfigurationOut
    const date = dateFormatter(new Date().toISOString())

    if (!selectedApplication) return
    const { category_id, sub_category_id, operator_group_id, ticket_type } = selectedApplication

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const specifications = Object.keys(rest).map(name => ({ name: name.replaceAll('_', ' '), value: rest[name] }))
      .filter(({ value }) => value !== undefined)

    // TopDesk does not accept new lines in the request, so we added \\r and \\n instead and replace then later
    const descriptionText = specifications?.map(question => `${question.name}: \\r\\n ${question.value}\\r\\n\\r\\n`).join('')

    return {
      request: `${date}: ${descriptionText}`,
      categoryId: category_id,
      subCategoryId: sub_category_id,
      operatorGroup: operator_group_id,
      callTypeName: ticket_type,
      entryTypeName: entryTypeName as string,
      briefDescription: briefDescription as string,
    }
  }

  const MockQuestions: FC = () =>
    <>
      <Grid item xs={12}>
        <Typography variant="h6" component="h2" className={classes.disabled}>Stap 2</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Question fields={{label:'Beschrijving', type: 'text', isRequired:'False'}} disabled={true} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Question fields={{label:'Reproductie scenario', type: 'text', isRequired:'False'}} disabled={true} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Question fields={{label:'Omgeving', type: 'text', isRequired:'False'}} disabled={true} />
      </Grid>
    </>

  return (
    <StyledCreate
      className={classes.root}
      transform={transform}
      actions={<ActionsToolbar i18n="resources.tickets.create" />}
      redirect="edit"
    >
      <SimpleForm
        defaultValues={{
          entryTypeName: 'mijn.xxllnc',
          priority: 'Laag'
        }}
        toolbar={<SimpleSaveToolbar saveLabel='data.send' />}
      >
        <Grid container spacing={2} className={classes.grid}>
          <Grid item xs={12}>
            <FormDataConsumer>
              {({ formData }) => {
                const totalParams = Object.keys(formData).length -5
                const filledParams = Object.values(formData).filter(val => val !== undefined).length -2
                const progress = (filledParams / totalParams) * 100 || 0

                return (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <LinearProgress className={classes.progress} variant="determinate" value={progress} />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2" color="text.secondary">{`${Math.round(progress)}%`}</Typography>
                    </Box>
                  </Box>
                )
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" className={classes.title}>Stap 1<span>Alle velden zijn verplicht</span></Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <AutocompleteInput
              source="tag"
              label="data.tag"
              choices={mergeUniqueTags().sort((a, b) => a.toString().localeCompare(b.toString()))
                .map((tag) => ({ id: tag, name: tag }))}
              validate={[required()]}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDataConsumer>
              {({ formData }) => (
                <SelectInput
                  source="application"
                  label="data.application"
                  validate={[required()]}
                  disabled={!formData.tag}
                  choices={ticketConfig?.filter(({ tags }) =>
                    tags?.includes(formData.tag))?.map(({ id, description }) => ({ id, name: description })) || []}
                />
              )}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              source="briefDescription"
              label="data.subject"
              validate={validateDescription}
              fullWidth
              helperText="data.max80characters"
              multiline
            />
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <FormDataConsumer>
            {({ formData }) => {
              const application = ticketConfig?.find(({ id }) => id === (formData.application ?? ''))
              return !formData.tag || !application ? <MockQuestions /> : (
                <>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      component="h2"
                      className={classes.title}
                    >
                      Stap 2<span>Velden met * zijn verplicht</span>
                    </Typography>
                  </Grid>
                  { application?.questions?.map((question: Record<string, string>) => (
                    <Grid key={question.source} item xs={12} md={6}><Question fields={question} /></Grid>
                  ))}
                </>
              )
            }}
          </FormDataConsumer>
        </Grid>
      </SimpleForm>
    </StyledCreate>
  )
}

export default TicketsCreate

const PREFIX = 'xxllncTicketsCreate'

const classes = {
  root: `${PREFIX}-root`,
  grid: `${PREFIX}-grid`,
  title: `${PREFIX}-title`,
  disabled: `${PREFIX}-disabled`,
  progress: `${PREFIX}-progress`,
  divider: `${PREFIX}-divider`,
}

const StyledCreate = styled(Create)(({ theme }) => ({
  [`&.${classes.root}`]: {
  },
  [`& .${classes.grid}`]: {
    padding: '8px 16px',
  },
  [`& .${classes.title}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    ['& span']: {
      fontSize: '8pt',
      fontWeight: 'normal',
      textAlign: 'right',
    },
  },
  [`& .${classes.disabled}`]: {
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  [`& .${classes.progress}`]: {
    '& span': {
      backgroundColor: xxllncColor.blue100,
    },
  },
  [`& .${classes.divider}`]: {
    display: 'block',
    margin: theme.spacing(4, 0),
  },
  '& .MuiFormHelperText-root': {
    height: '1px',
  },
}))