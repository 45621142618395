import { FC } from 'react'
import { Edit, SimpleForm } from 'react-admin'
import { SimpleSaveToolbar } from '../../components'
import TicketForm from './TicketForm'

const TicketsEdit: FC = () => (
  <Edit redirect={false} mutationMode="optimistic">
    <SimpleForm
      toolbar={<SimpleSaveToolbar saveLabel='resources.tickets.sendReaction' />}
      sanitizeEmptyValues
    >
      <TicketForm />
    </SimpleForm>
  </Edit>
)

export default TicketsEdit
