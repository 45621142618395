import React from 'react'
import { styled } from '@mui/material'
import { TopToolbar, CreateButton, Datagrid, DateField, List, TextField, useListContext, useTranslate } from 'react-admin'
import { Typography } from '@mui/material'
import { xxllncColor } from '../../layout/themes'
import { StatusField } from '../../components/fields/StatusField'
import { TicketSkeletonList } from './TicketSkeletonList'
import { TicketRecord } from '../Tickets/Tickets.list'

const ListActions = () => (
  <TopToolbar>
    <CreateButton label="resources.tickets.create"/>
  </TopToolbar>
)

const ListContent = () => {
  const { data } = useListContext<TicketRecord>()

  if (!data) return (
    <TicketSkeletonList />
  )

  return (
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField
        source="number"
        label="data.ticketNumber"
        sortable={false}
      />
      <TextField
        source="briefDescription"
        label="data.briefDescription"
        emptyText="-"
        sortable={false}
      />
      <DateField
        source="creationDate"
        label="data.created"
        sortable={false}
      />
      <StatusField />
    </Datagrid>
  )
}

const TicketsList = props => {
  const translate = useTranslate()

  return (
    <StyledList
      {...props}
      pagination={null}
      exporter={false}
      className={classes.root}
      actions={<ListActions/>}
    >
      <Typography variant="h6" className={classes.header}>
        {translate('dashboard.tickets')}
      </Typography>
      <ListContent />
    </StyledList>
  )
}

export default TicketsList

const PREFIX = 'aipDashboardTicketsList'

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`&.${classes.root}`]: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
    },
    ['& .MuiToolbar-root']: {
      [theme.breakpoints.up('md')]: {
        minHeight: '8px',
        padding: '12px 0px 0px 0px',
      },
    },
    [`& .${classes.header}`]: {
      fontWeight: 600,
      marginBottom: theme.spacing(1),
    },
    '& .RaCreateButton-root': {
      backgroundColor: xxllncColor.dark100,
      color: xxllncColor.light100,
    },
  },
}))