import { Grid, styled, Typography } from '@mui/material'
import { FC } from 'react'
import { useGetList, useListContext, useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import { moreAppsBase64 } from '../../components/Icons'
import { AppOrganizationOut, AppOverview } from '../../types/ecoRequests'
import { getOrganizationId } from '../../utils/userDetails'
import { AppWithOrganization } from '../Apps/App.list'
import { AppCard } from './AppCard'
import { AppSkeletonCard } from './AppSkeletonCard'

const AppGrid: FC = () => {
  const translate = useTranslate()
  const { data } = useListContext<AppOverview>()

  const { data: appOrganizations } = useGetList<AppOrganizationOut>(
    'app_organization',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
      filter: { organizationId: getOrganizationId() }
    }
  )

  const mergeData = (appOverview: AppOverview[]): AppWithOrganization[] =>
    appOrganizations?.map(appOrg => {
      const matchingApp = appOverview.find(app => app.id === appOrg.appId)
      if (matchingApp) {
        return {
          ...matchingApp,
          appOrganizationId: appOrg.id
        }
      }
      return null
    }).filter(app => app !== null) as AppWithOrganization[]

  return (
    <StyledGrid>
      <Grid container>
        <Typography variant="h6" className={classes.header}>
          {translate('dashboard.applications')}
        </Typography>
      </Grid>
      <Grid container spacing={2} className={classes.container} style={{ display: 'flex', alignItems: 'stretch' }}>
        {data && mergeData(data)?.slice(0, 3).map((record): JSX.Element => (
          <Grid item key={record.id} xs={12} sm={6} md={3} xl={3}>
            <Link
              to={`/app_overview/${record.id}/show/${record.appOrganizationId ? `?appOrganizationId=${record.appOrganizationId}` : ''}`}
              className={classes.link}>
              <AppCard {...record} description={record?.description.slice(0, 100)} />
            </Link>
          </Grid>
        )) || (
          <>
            <AppSkeletonCard />
            <AppSkeletonCard />
            <AppSkeletonCard />
          </>
        )}
        <Grid item key={'add_app'} xs={12} sm={6} md={3} xl={3}>
          <Link to={'/app_overview'} className={classes.link}>
            <AppCard
              name={translate('dashboard.allApps')}
              icon={moreAppsBase64}
              description={translate('dashboard.allAppsDescription')}
            />
          </Link>
        </Grid>
      </Grid>
    </StyledGrid>
  )
}

const PREFIX = 'xxllncAppGrid'

const classes = {
  hidden: `${PREFIX}-hidden`,
  container: `${PREFIX}-container`,
  header: `${PREFIX}-header`,
  link: `${PREFIX}-link`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.hidden}`]: {
    display: 'none',
  },
  [`& .${classes.container}`]: {
    marginBottom: theme.spacing(1.5),
    marginTop: 0,
    ['& .MuiGrid-item']: {
      paddingTop: 0,
    },
  },
  [`& .${classes.header}`]: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  [`& .${classes.link}`]: {
    textDecoration: 'none',
    display: 'flex',
    height: '100%',
  },
}))

export default AppGrid
