import { Box } from '@mui/material'
import { FC } from 'react'
import {
  Datagrid,
  DateField,
  FunctionField,
  RaRecord,
  TextField,
  useTranslate
} from 'react-admin'
import CustomList from '../../components/list/CustomList'
import { TicketsListSidebar } from './Tickets.list.sidebar'

export interface TicketRecord extends RaRecord {
  id: string
  callerName: string
  closed: boolean
  creationDate: string
  closedDate: string
  targetDate: string
  request: string
  status: string
  type: string
  category: string
  subCategory: string
  reactions: {
    id: string
    person?: string
    operator?: string
    memoText?: string
    fileName?: string
    downloadUrl?: string
    entryDate: string
  }[]
}

const FilterSidebar = () => (
  <Box sx={{ width: '100%', order: -1 }}>
    <TicketsListSidebar />
  </Box>
)

export const extractRequest = (record: TicketRecord | undefined) => {
  const input = record?.request
  return input?.substring(input.indexOf('\n') + 1) || '-'
}

const TicketsList: FC = () => {
  const translate = useTranslate()

  return (
    <CustomList
      title='resources.tickets.list'
      sort={{ field: 'number', order: 'DESC' }}
      exporter={false}
      aside={<FilterSidebar />}
    >
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField
          source="number"
          label="data.ticketNumber"
        />
        <TextField
          source="briefDescription"
          label="data.briefDescription"
          emptyText="-"
        />
        <FunctionField
          label="data.status"
          render={(record: TicketRecord) => translate(`resources.tickets.status.${record.status}`)}
        />
        <DateField
          source="creationDate"
          label="data.created"
        />
      </Datagrid>
    </CustomList>
  )
}

export default TicketsList
