import { styled } from '@mui/material'
import { FC } from 'react'
import { CreateButton, FilterList, FilterLiveSearch } from 'react-admin'
import { ClosedFilter } from '../../components'

export const TicketsListSidebar: FC = () => (
  <StyledFilterList className={classes.root} label="filters.domain" icon={undefined}>
    <FilterLiveSearch source="q" className={classes.item} />
    <ClosedFilter />
    <CreateButton label='actions.createTicket' variant="contained" />
  </StyledFilterList>
)

const PREFIX = 'xxllncTicketsSidebar'

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
}

const StyledFilterList = styled(FilterList)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    marginBottom: '20px',
  },
  [`& .${classes.item}`]: {
    border: '1px solid #E0E0E0',
    marginRight: '10px',
  },
  '& .MuiBox-root': {
    display: 'none',
  },
  '& .MuiList-root': {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .MuiList-root > a:last-child': {
    marginLeft: 'auto',
  },
}))