import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import TicketsEdit from './Tickets.edit'
import TicketsCreate from './Tickets.create'
import TicketsList from './Tickets.list'

const resource = {
  list: TicketsList,
  edit: TicketsEdit,
  create: TicketsCreate,
  icon: ConfirmationNumberIcon,
}

export default resource
