import { FC } from 'react'
import {
  styled,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'

export const TicketSkeletonList: FC = () => (
  <DatagridRoot className={DatagridClasses.root}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Nummer</TableCell>
          <TableCell>Korte omschrijving</TableCell>
          <TableCell>Aangemaakt</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell><Skeleton /></TableCell>
          <TableCell><Skeleton /></TableCell>
          <TableCell><Skeleton /></TableCell>
          <TableCell><Skeleton /></TableCell>
        </TableRow>
        <TableRow>
          <TableCell><Skeleton /></TableCell>
          <TableCell><Skeleton /></TableCell>
          <TableCell><Skeleton /></TableCell>
          <TableCell><Skeleton /></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </DatagridRoot>
)

const PREFIX = 'xxllncTicketSkeletonList'

export const DatagridClasses = {
  root: `${PREFIX}-root`,
}

export const DatagridRoot = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(() => ({
  ['& .MuiTableCell-head.MuiTableCell-root']: {
    background: '#F2F2F2',
    borderTop: '0'
  },
  ['& .MuiTableCell-root']: {
    fontSize: '12px',
    fontWeight: '600',
    padding: '6px 12px',
    color: 'rgba(29,29,29,1)',
  },
}))