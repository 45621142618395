import { DateInput, DateTimeInput, required, SelectInput, TextInput } from 'react-admin'

interface QuestionProps {
  fields: Record<string, string>
  disabled?: boolean
}

export const Question = (question: QuestionProps) => {
  const { fields, disabled, ...rest } = question
  const { label, type, isRequired, options } = fields

  const sourceFromLabel = label.toLowerCase().replace(/ /g, '_')
  const optionsArrayFromString = options?.split(',').map(option => option.trim())
  const validateDescription = isRequired === 'True' ? [required()] : []

  switch (type) {
    case 'text': return (
      <TextInput
        { ...rest}
        source={sourceFromLabel}
        validate={validateDescription}
        disabled={disabled}
        fullWidth
      />)
    case 'multiline': return (
      <TextInput
        { ...rest}
        source={sourceFromLabel}
        validate={validateDescription}
        disabled={disabled}
        multiline
        fullWidth
      />)
    case 'select': return (
      <SelectInput
        { ...rest}
        source={sourceFromLabel}
        choices={optionsArrayFromString?.map(option => ({ id: option, name: option })) || []}
        validate={validateDescription}
        disabled={disabled}
        fullWidth
      />)
    case 'date':
      return (
        <DateInput
          { ...rest }
          source={sourceFromLabel}
          validate={validateDescription}
          disabled={disabled}
          fullWidth
        />
      )
    case 'datetime': return (
      <DateTimeInput
        { ...rest }
        source={sourceFromLabel}
        validate={validateDescription}
        disabled={disabled}
        fullWidth
      />
    )
    default: return null
  }
}