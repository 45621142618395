import { useRecordContext } from 'react-admin'
import { styled } from '@mui/material'

const StatusCircle = styled('span')`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  margin-bottom: 1px;
`

export const StatusField = (props) => {
  const record = useRecordContext(props)
  const backgroundColor = record?.status === 'Nieuw' ? '#006E5B' : 'gray'

  return <span><StatusCircle style={{ backgroundColor }} />{record?.status || ''}</span>
}

StatusField.defaultProps = { label: 'Status' }